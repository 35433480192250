import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from '../../util/reactIntl';
import BrandingIconCard from '../BrandingIconCard/BrandingIconCard';
import css from './TransactionTable.module.css';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';

/**
 * TransactionTable component displays a table of transactions.
 * It can display different status information based on whether the
 * transactions represent orders or bookings.
 *
 * @param {Array} transactions - List of transactions to display.
 * @param {boolean} isOrder - Flag to indicate if the transactions are orders.
 * @param {boolean} isBooking - Flag to indicate if the transactions are bookings.
 * @returns {JSX.Element} The rendered TransactionTable component.
 */
const TransactionTable = ({ transactions = [], isOrder = false, isBooking = false }) => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  // Check if there are no transactions to display
  if (!transactions || transactions.length === 0) {
    return <div>No transactions available.</div>;
  }

  // Define a mapping for transitions to their respective status messages
  const transitionStatusMap = {
    'transition/request-payment': 'Pending',
    'transition/pre-auth-payment': 'Pending',
    'transition/confirm-payment': 'Confirmed',
    'transition/mark-delivered': 'Delivered',
    'transition/mark-received': 'Received',
    'transition/auto-complete': 'Completed',
  };

  return (
    <div className={css.bookingOrder}>
      <div className={css.bookingHeader}>
        <div className={css.bookingHeading}>
          <FormattedMessage id="BookingTable.orders" defaultMessage="Orders" />
        </div>
      </div>
      <div className={css.bookingTable}>
        <table>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="BookingTable.product" defaultMessage="Product" />
              </th>
              <th>
                <FormattedMessage id="BookingTable.customer" defaultMessage="Customer" />
              </th>
              <th>
                <FormattedMessage id="BookingTable.deal" defaultMessage="Deal" />
              </th>
              <th>
                <FormattedMessage id="BookingTable.date" defaultMessage="Date" />
              </th>
              <th>
                <FormattedMessage id="BookingTable.time" defaultMessage="Time" />
              </th>
              <th>
                <FormattedMessage id="BookingTable.amount" defaultMessage="Amount" />
              </th>
              <th>
                <FormattedMessage id="BookingTable.dealType" defaultMessage="Deal Type" />
              </th>
              <th>
                <FormattedMessage id="BookingTable.status" defaultMessage="Status" />
              </th>
              <th>
                <FormattedMessage id="BookingTable.actions" defaultMessage="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => {
              const { attributes, provider, customer, listing, booking } = transaction;

              // Extract relevant information from the transaction
              const serviceTitle = listing.attributes.title;
              const customerName = customer.attributes.profile.displayName;
              const dealType = attributes.categgoryLevel2 ? 'Y' : 'N';

              // Format date and time based on the booking information
              const date = booking
                ? new Date(booking.attributes.start).toLocaleDateString()
                : new Date(attributes.lastTransitionedAt).toLocaleDateString();

              const time = booking
                ? new Date(booking.attributes.start).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                : new Date(attributes.lastTransitionedAt).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  });

              const amount = `${(attributes.payoutTotal.amount / 100).toFixed(2)} ${
                attributes.payoutTotal.currency
              }`;

              // Determine the status using a ternary operator based on isOrder and isBooking
              const status = isOrder
                ? attributes.transitions.length > 0
                  ? transitionStatusMap[
                      attributes.transitions[attributes.transitions.length - 1].transition
                    ] || 'N/A'
                  : 'N/A'
                : isBooking
                ? booking
                  ? booking.attributes.state
                  : 'N/A'
                : 'N/A';

              const id = transaction?.id?.uuid;

              return (
                <tr
                  onClick={() => {
                    history.push(
                      createResourceLocatorString('SaleDetailsPage', routeConfiguration, { id }, {})
                    );
                  }}
                  key={index}
                  style={{ cursor: 'pointer' }}
                >
                  <td>
                    <span className={css.heading}>{serviceTitle}</span>
                  </td>
                  <td>
                    <span className={css.mainHeading}>{customerName}</span>
                  </td>
                  <td>
                    <span className={css.mainHeading}>{dealType}</span>
                  </td>
                  <td>
                    <span className={css.mainHeading}>{date}</span>
                  </td>
                  <td>
                    <span className={css.mainHeading}>{time}</span>
                  </td>
                  <td>
                    <span className={css.mainHeading}>{amount}</span>
                  </td>
                  <td>
                    <span className={css.amountDiscountBadge}>{'---'}</span>
                  </td>
                  <td>
                    <span className={css.bookedText}>{status}</span>
                  </td>
                  <td>
                    <div className={css.actionButton}>
                      <span className={css.actionIcon}>
                        <BrandingIconCard type="edit" />
                      </span>
                      <span className={css.actionIcon}>
                        <BrandingIconCard type="delete" />
                      </span>
                      <span className={css.actionIcon}>
                        <BrandingIconCard type="horizantaldots" />
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

TransactionTable.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.shape({
        processName: PropTypes.string.isRequired,
        payoutTotal: PropTypes.shape({
          amount: PropTypes.number.isRequired,
          currency: PropTypes.string.isRequired,
        }).isRequired,
        transitions: PropTypes.arrayOf(
          PropTypes.shape({
            transition: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            by: PropTypes.string.isRequired,
          })
        ).isRequired,
        lastTransitionedAt: PropTypes.string.isRequired,
      }).isRequired,
      provider: PropTypes.shape({
        attributes: PropTypes.shape({
          profile: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
      customer: PropTypes.shape({
        attributes: PropTypes.shape({
          profile: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
      listing: PropTypes.shape({
        attributes: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      booking: PropTypes.shape({
        attributes: PropTypes.shape({
          state: PropTypes.string,
          start: PropTypes.string,
        }),
      }),
    })
  ).isRequired,
};

export default TransactionTable;
