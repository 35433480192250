import React from 'react';
import { useSelector } from 'react-redux';
import { LayoutSingleColumn, Page, PaginationLinks, UserNav } from '../../components';
import TransactionTable from '../../components/TransactionTable/TransactionTable';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import css from './OrderPage.module.css';

/**
 * OrderPage component displays the user's order or sale transactions.
 * It includes a transaction table, user navigation, and pagination links.
 */
const OrderPage = ({ params }) => {
  // Extracting state values using Redux selectors
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs } = useSelector(
    state => state?.OrderPage
  );

  const { currentUser, currentUserNotificationCount: providerNotificationCount } = useSelector(
    state => state.user
  );

  const appState = useSelector(state => state);
  const transactions = getMarketplaceEntities(appState, transactionRefs);
  const scrollingDisabled = useSelector(isScrollingDisabled);

  /**
   * Check if the user has order or sale transactions.
   * @param {Array} transactions - The list of transactions.
   * @param {Object} user - The current user object.
   * @returns {boolean} - True if the user has transactions, false otherwise.
   */
  const hasUserTransactions = (transactions, user) => {
    return (
      user?.id &&
      transactions &&
      transactions.length > 0 &&
      transactions[0].provider.id.uuid === user?.id?.uuid
    );
  };

  const hasTransactions = !fetchInProgress && hasUserTransactions(transactions, currentUser);

  return (
    <Page title={'Order Page'} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        topbar={
          <>
            <TopbarContainer />
            <UserNav currentPage="OrderPage" />
          </>
        }
        footer={<FooterContainer />}
      >
        <TransactionTable transactions={transactions} isOrder={true} isBooking={false} />
        {hasTransactions && pagination && pagination.totalPages > 1 ? (
          <PaginationLinks
            className={css.pagination}
            pageName="OrderPage"
            pagePathParams={params}
            pagination={pagination}
          />
        ) : null}
      </LayoutSingleColumn>
    </Page>
  );
};

export default OrderPage;
