import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { onGetCategories } from '../../util/api';
import { updatedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig, types as sdkTypes } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { addOwnEntities } from '../ManageListingsPage/ManageListingsPage.duck';
import { DEAL_LISTING_TYPE } from '../../util/types';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';

const { UUID } = sdkTypes;
export const ASSET_NAME = 'landing-page';

export const SEARCH_LISTINGS_REQUEST = 'app/AlgoliaSearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/AlgoliaSearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/AlgoliaSearchPage/SEARCH_LISTINGS_ERROR';
export const ALGOLIA_SEARCH_RESULTS = 'app/AlgoliaSearchPage/ALGOLIA_SEARCH_RESULTS';

export const CATEGORIES_REQUEST = 'app/LandingPage/CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'app/LandingPage/CATEGORIES_SUCCESS';
export const CATEGORIES_ERROR = 'app/LandingPage/CATEGORIES_ERROR';

export const FETCH_LISTINGS_REQUEST = 'app/LandingPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/LandingPage/FETCH_LISTINGS_ERROR';

export const ADD_OWN_ENTITIES = 'app/LandingPage/ADD_OWN_ENTITIES';

const RESULT_PAGE_SIZE = 42;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/LandingPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/LandingPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/LandingPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  searchParams: null,
  pagination: null,
  searchInProgress: false,
  searchListings: [],
  searchListingsError: null,
  algoliaSearchResults: [],
  categoriesRequest: false,
  categoriesSuccess: [],
  categoriesError: null,
  transactionRefs: [],
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case ALGOLIA_SEARCH_RESULTS:
      return {
        ...state,
        algoliaSearchResults: payload,
      };
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload,
        searchInProgress: true,
        pagination: null,
        searchListings: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        searchInProgress: false,
        searchListingsError: payload,
        searchListings: [],
        pagination: null,
      };

    case CATEGORIES_REQUEST:
      return {
        ...state,
        categoriesRequest: true,
      };

    case CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesRequest: false,
        categoriesSuccess: payload,
      };

    case CATEGORIES_ERROR:
      return {
        ...state,
        categoriesError: payload,
      };
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload);
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //
export const algoliasearchResults = data => ({
  type: ALGOLIA_SEARCH_RESULTS,
  payload: data,
});
export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: searchParams,
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: response,
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const categoriesRequest = () => ({
  type: CATEGORIES_REQUEST,
});

export const categoriesSuccess = payload => ({
  type: CATEGORIES_SUCCESS,
  payload: payload,
});

export const categoriesError = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: response,
});

export const categoriesListing = () => dispatch => {
  dispatch(categoriesError);
  try {
    onGetCategories()
      .then(res => {
        dispatch(categoriesSuccess(res));
        return res;
      })
      .catch(err => categoriesError(err));
  } catch (error) {
    dispatch(categoriesError(error.message));
  }
};

export const searchListings = searchParams => async (dispatch, getState, sdk) => {
  try {
    //fetching result in-progress
    dispatch(searchListingsRequest());
  } catch (error) {
    console.error('error..', error);
    dispatch(searchListingsSuccess());
  }
};

export const getAlgoliasearchResults = params => (dispatch, getState, sdk) => {
  try {
    dispatch(algoliasearchResults(params));
  } catch (error) { }
};

export const fetchAuthorData = ({ userId }) => async (dispatch, getState, sdk) => {
  try {
    const result = await sdk.users.show({ id: new UUID(userId) });
    return result?.data?.data;
  } catch (error) { }
};

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  return sdk.ownListings
    .query(params)
    .then(response => {
      dispatch(addOwnEntities(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};
export const loadData = (params, search, config) => (dispatch, getState, sdk) => {

  const { tab, state } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  // const onlyFilter = onlyFilterValues[tab];
  // if (!onlyFilter) {
  //   return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  // }
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };

  dispatch(fetchOrdersOrSalesRequest());

  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const apiQueryParams = {
    only: "sale",
    lastTransitions: getAllTransitionsForEveryProcess(),
    // processNames: state === 'bookings' ? ['default-booking'] : ['default-purchase'],
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));

      const {
        aspectWidth = 1,
        aspectHeight = 1,
        variantPrefix = 'listing-card',
      } = config.layout.listingImage;
      const aspectRatio = aspectHeight / aspectWidth;

      dispatch(queryOwnListings({
        ...queryParams,
        page,
        pub_listingType: DEAL_LISTING_TYPE,
        perPage: RESULT_PAGE_SIZE,
        include: ['images', 'currentStock'],
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      }))

      return (
        response &&
        Promise.all([dispatch(searchListings()), dispatch(fetchPageAssets(pageAsset, true))])
      );
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
