// import { onSendBuzz } from '../../util/api';
import { onSendBuzzNotification } from '../../util/api';
import { denormalisedEntities, updatedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';

const RESULT_PAGE_SIZE = 42;

// Action Types
export const ACTION_BUZZ_REQUEST = 'app/SendBuzzPage/ACTION_BUZZ_REQUEST';
export const ACTION_BUZZ_SUCCESS = 'app/SendBuzzPage/ACTION_BUZZ_SUCCESS';
export const ACTION_BUZZ_ERROR = 'app/SendBuzzPage/ACTION_BUZZ_ERROR';

export const ACTION_FETCH_LISTINGS_REQUEST = 'app/SendBuzzPage/ACTION_FETCH_LISTINGS_REQUEST';
export const ACTION_FETCH_LISTINGS_SUCCESS = 'app/SendBuzzPage/ACTION_FETCH_LISTINGS_SUCCESS';
export const ACTION_FETCH_LISTINGS_ERROR = 'app/SendBuzzPage/ACTION_FETCH_LISTINGS_ERROR';

export const ACTION_OPEN_LISTING_REQUEST = 'app/SendBuzzPage/ACTION_OPEN_LISTING_REQUEST';
export const ACTION_OPEN_LISTING_SUCCESS = 'app/SendBuzzPage/ACTION_OPEN_LISTING_SUCCESS';
export const ACTION_OPEN_LISTING_ERROR = 'app/SendBuzzPage/ACTION_OPEN_LISTING_ERROR';

export const ACTION_CLOSE_LISTING_REQUEST = 'app/SendBuzzPage/ACTION_CLOSE_LISTING_REQUEST';
export const ACTION_CLOSE_LISTING_SUCCESS = 'app/SendBuzzPage/ACTION_CLOSE_LISTING_SUCCESS';
export const ACTION_CLOSE_LISTING_ERROR = 'app/SendBuzzPage/ACTION_CLOSE_LISTING_ERROR';

export const ACTION_ADD_OWN_ENTITIES = 'app/SendBuzzPage/ACTION_ADD_OWN_ENTITIES';

// Initial state for the buzz reducer
const initialState = {
  loading: false,
  data: null,
  error: null,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListingId: null,
  openingListingError: null,
  closingListingId: null,
  closingListingError: null,
};

// Extract result IDs from listings data
const extractResultIds = listingsData => listingsData.data.map(listing => listing.id);

// Merge the updated entities into the state
const mergeEntities = (state, sdkResponse) => {
  const apiResponseData = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponseData),
  };
};

// Update attributes of a specific listing
const updateListingAttributes = (state, updatedListingEntity) => {
  const existingListing = state.ownEntities.ownListing[updatedListingEntity.id.uuid];
  const updatedListing = { ...existingListing, attributes: updatedListingEntity.attributes };

  const updatedOwnListingEntities = {
    ...state.ownEntities.ownListing,
    [updatedListingEntity.id.uuid]: updatedListing,
  };

  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: updatedOwnListingEntities },
  };
};

/**
 * Buzz reducer to manage the state related to sending a buzz.
 * @param {Object} state - The current state of the buzz functionality.
 * @param {Object} action - The action dispatched.
 * @returns {Object} - The new state after processing the action.
 */
const buzzReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_BUZZ_REQUEST:
      return {
        ...state,
        loading: true,
        error: null, // Reset error when making a new request
      };
    case ACTION_BUZZ_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ACTION_BUZZ_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACTION_FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: action.payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case ACTION_FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: extractResultIds(action.payload.data),
        pagination: action.payload.data.meta,
        queryInProgress: false,
      };
    case ACTION_FETCH_LISTINGS_ERROR:
      console.error(action.payload);
      return { ...state, queryInProgress: false, queryListingsError: action.payload };

    case ACTION_OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListingId: action.payload.listingId,
        openingListingError: null,
      };
    case ACTION_OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, action.payload.data),
        openingListingId: null,
      };
    case ACTION_OPEN_LISTING_ERROR:
      console.error(action.payload);
      return {
        ...state,
        openingListingId: null,
        openingListingError: {
          listingId: state.openingListingId,
          error: action.payload,
        },
      };

    case ACTION_CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListingId: action.payload.listingId,
        closingListingError: null,
      };
    case ACTION_CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, action.payload.data),
        closingListingId: null,
      };
    case ACTION_CLOSE_LISTING_ERROR:
      console.error(action.payload);
      return {
        ...state,
        closingListingId: null,
        closingListingError: {
          listingId: state.closingListingId,
          error: action.payload,
        },
      };

    case ACTION_ADD_OWN_ENTITIES:
      return mergeEntities(state, action.payload);

    default:
      return state;
  }
};

export default buzzReducer;

/**
 * Retrieves the denormalized own listing entities by their IDs.
 *
 * @param {Object} state - The full Redux store state.
 * @param {Array<UUID>} listingIds - Listing IDs to fetch from the store.
 * @returns {Object} - Denormalized entities.
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.SendBuzzPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

/**
 * Action creator to add own entities to the state.
 *
 * @param {Object} sdkResponse - The SDK response containing entities.
 * @returns {Object} - The action object for adding own entities.
 */
export const addOwnEntities = sdkResponse => ({
  type: ACTION_ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

/**
 * Action creator to request opening a listing.
 *
 * @param {UUID} listingId - The ID of the listing to open.
 * @returns {Object} - The action object for opening a listing.
 */
export const openListingRequest = listingId => ({
  type: ACTION_OPEN_LISTING_REQUEST,
  payload: { listingId },
});

/**
 * Action creator for successful listing opening.
 *
 * @param {Object} response - The response data from the open listing request.
 * @returns {Object} - The action object for successful opening.
 */
export const openListingSuccess = response => ({
  type: ACTION_OPEN_LISTING_SUCCESS,
  payload: response.data,
});

/**
 * Action creator for failed listing opening.
 *
 * @param {Error} error - The error object.
 * @returns {Object} - The action object for opening error.
 */
export const openListingError = error => ({
  type: ACTION_OPEN_LISTING_ERROR,
  error: true,
  payload: error,
});

/**
 * Action creator to request closing a listing.
 *
 * @param {UUID} listingId - The ID of the listing to close.
 * @returns {Object} - The action object for closing a listing.
 */
export const closeListingRequest = listingId => ({
  type: ACTION_CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

/**
 * Action creator for successful listing closure.
 *
 * @param {Object} response - The response data from the close listing request.
 * @returns {Object} - The action object for successful closure.
 */
export const closeListingSuccess = response => ({
  type: ACTION_CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

/**
 * Action creator for failed listing closure.
 *
 * @param {Error} error - The error object.
 * @returns {Object} - The action object for closing error.
 */
export const closeListingError = error => ({
  type: ACTION_CLOSE_LISTING_ERROR,
  error: true,
  payload: error,
});

/**
 * Action creator to request fetching listings.
 *
 * @param {Object} queryParams - The parameters for querying listings.
 * @returns {Object} - The action object for fetching listings.
 */
export const queryListingsRequest = queryParams => ({
  type: ACTION_FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

/**
 * Action creator for successful listings fetch.
 *
 * @param {Object} response - The response data from the fetch listings request.
 * @returns {Object} - The action object for successful fetch.
 */
export const queryListingsSuccess = response => ({
  type: ACTION_FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

/**
 * Action creator for failed listings fetch.
 *
 * @param {Error} error - The error object.
 * @returns {Object} - The action object for fetch error.
 */
export const queryListingsError = error => ({
  type: ACTION_FETCH_LISTINGS_ERROR,
  error: true,
  payload: error,
});

/**
 * Thunk action to query own listings with specified parameters.
 *
 * @param {Object} queryParams - The parameters for querying listings.
 * @returns {Function} - A function that dispatches actions based on the API response.
 */
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...restParams } = queryParams;
  const params = { ...restParams, perPage };

  return sdk.ownListings
    .query(params)
    .then(response => {
      dispatch(addOwnEntities(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(error => {
      dispatch(queryListingsError(storableError(error)));
      throw error;
    });
};

/**
 * Thunk action to close a listing.
 *
 * @param {UUID} listingId - The ID of the listing to close.
 * @returns {Function} - A function that dispatches actions based on the API response.
 */
export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(error => {
      dispatch(closeListingError(storableError(error)));
    });
};

/**
 * Thunk action to open a listing.
 *
 * @param {UUID} listingId - The ID of the listing to open.
 * @returns {Function} - A function that dispatches actions based on the API response.
 */
export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(error => {
      dispatch(openListingError(storableError(error)));
    });
};

/**
 * Action creator to initiate a buzz request.
 *
 * @returns {Object} - The action object to initiate a buzz.
 */
export const buzzRequest = () => ({
  type: ACTION_BUZZ_REQUEST,
});

/**
 * Action creator for a successful buzz.
 *
 * @param {Object} data - The data returned from the successful buzz request.
 * @returns {Object} - The action object containing the data payload.
 */
export const buzzSuccess = data => ({
  type: ACTION_BUZZ_SUCCESS,
  payload: data,
});

/**
 * Action creator for a failed buzz.
 *
 * @param {String} error - The error message.
 * @returns {Object} - The action object containing the error payload.
 */
export const buzzError = error => ({
  type: ACTION_BUZZ_ERROR,
  payload: error,
});

/**
 * Thunk action to send a buzz.
 *
 * @param {Object} buzzData - The data to send in the buzz.
 * @returns {Function} - A function that dispatches actions based on the API response.
 */
export const sendBuzz = buzzData => async (dispatch, getState) => {
  const { currentUser } = getState()?.user;
  const { deal } = buzzData;

  if (deal && Object.keys(deal).length > 0) {
    buzzData.deal = {
      ...deal,
      author: currentUser,
    };
  }

  dispatch(buzzRequest());
  return onSendBuzzNotification({
    buzzData: { ...buzzData, templateId: '29060bfd-1da2-4f7d-b808-4e6e7a637fa3' },
  })
    .then(response => {
      dispatch(buzzSuccess(response));
      return response;
    })
    .catch(error => {
      dispatch(buzzError('Error sending buzz!', error));
    });
};

/**
 * Loads data based on specified parameters, search, and layout configuration.
 *
 * @param {Object} params - Parameters for loading data.
 * @param {String} search - The search query string.
 * @param {Object} config - Configuration for layout.
 * @returns {Function} - A function that queries own listings with the constructed parameters.
 */
export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const currentPage = queryParams.page || 1;
  const { listingType } = params;
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return queryOwnListings({
    ...queryParams,
    pub_listingType: 'deal',
    page: currentPage,
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });
};
