import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import { sanitizeEntity } from './sanitize';

const uuidMap = new Map();
// NOTE: This file imports sanitize.js, which may lead to circular dependency

/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
  if (!oldRels && !newRels) {
    // Special case to avoid adding an empty relationships object when
    // none of the resource objects had any relationships.
    return null;
  }
  return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
  const { id, type } = oldRes;
  if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
    throw new Error('Cannot merge resource objects with different ids or types');
  }
  const attributes = newRes.attributes || oldRes.attributes;
  const attributesOld = oldRes.attributes || {};
  const attributesNew = newRes.attributes || {};
  // Allow (potentially) sparse attributes to update only relevant fields
  const attrs = attributes ? { attributes: { ...attributesOld, ...attributesNew } } : null;
  const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
  const rels = relationships ? { relationships } : null;
  return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse, sanitizeConfig = {}) => {
  const { data, included = [] } = apiResponse;
  const objects = (Array.isArray(data) ? data : [data]).concat(included);

  const newEntities = objects.reduce((entities, curr) => {
    const { id, type } = curr;

    // Some entities (e.g. listing and user) might include extended data,
    // you should check if src/util/sanitize.js needs to be updated.
    const current = sanitizeEntity(curr, sanitizeConfig);

    entities[type] = entities[type] || {};
    const entity = entities[type][id.uuid];
    entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

    return entities;
  }, oldEntities);

  return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
  const denormalised = resources.map(res => {
    const { id, type } = res;
    const entityFound = entities[type] && id && entities[type][id.uuid];
    if (!entityFound) {
      if (throwIfNotFound) {
        throw new Error(`Entity with type "${type}" and id "${id ? id.uuid : id}" not found`);
      }
      return null;
    }
    const entity = entities[type][id.uuid];
    const { relationships, ...entityData } = entity;

    if (relationships) {
      // Recursively join in all the relationship entities
      return reduce(
        relationships,
        (ent, relRef, relName) => {
          // A relationship reference can be either a single object or
          // an array of objects. We want to keep that form in the final
          // result.
          const hasMultipleRefs = Array.isArray(relRef.data);
          const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
          if (!relRef.data || multipleRefsEmpty) {
            ent[relName] = hasMultipleRefs ? [] : null;
          } else {
            const refs = hasMultipleRefs ? relRef.data : [relRef.data];

            // If a relationship is not found, an Error should be thrown
            const rels = denormalisedEntities(entities, refs, true);

            ent[relName] = hasMultipleRefs ? rels : rels[0];
          }
          return ent;
        },
        entityData
      );
    }
    return entityData;
  });
  return denormalised.filter(e => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = sdkResponse => {
  const apiResponse = sdkResponse.data;
  const data = apiResponse.data;
  const resources = Array.isArray(data) ? data : [data];

  if (!data || resources.length === 0) {
    return [];
  }

  const entities = updatedEntities({}, apiResponse);
  return denormalisedEntities(entities, resources);
};

/**
 * Denormalize JSON object.
 * NOTE: Currently, this only handles denormalization of image references
 *
 * @param {JSON} data from Asset API (e.g. page asset)
 * @param {JSON} included array of asset references (currently only images supported)
 * @returns deep copy of data with images denormalized into it.
 */
const denormalizeJsonData = (data, included) => {
  let copy;

  // Handle strings, numbers, booleans, null
  if (data === null || typeof data !== 'object') {
    return data;
  }

  // At this point the data has typeof 'object' (aka Array or Object)
  // Array is the more specific case (of Object)
  if (data instanceof Array) {
    copy = data.map(datum => denormalizeJsonData(datum, included));
    return copy;
  }

  // Generic Objects
  if (data instanceof Object) {
    copy = {};
    Object.entries(data).forEach(([key, value]) => {
      // Handle denormalization of image reference
      const hasImageRefAsValue =
        typeof value == 'object' && value?._ref?.type === 'imageAsset' && value?._ref?.id;
      // If there is no image included,
      // the _ref might contain parameters for image resolver (Asset Delivery API resolves image URLs on the fly)
      const hasUnresolvedImageRef = typeof value == 'object' && value?._ref?.resolver === 'image';

      if (hasImageRefAsValue) {
        const foundRef = included.find(inc => inc.id === value._ref?.id);
        copy[key] = foundRef;
      } else if (hasUnresolvedImageRef) {
        // Don't add faulty image ref
        // Note: At the time of writing, assets can expose resolver configs,
        //       which we don't want to deal with.
      } else {
        copy[key] = denormalizeJsonData(value, included);
      }
    });
    return copy;
  }

  throw new Error("Unable to traverse data! It's not JSON.");
};

/**
 * Denormalize asset json from Asset API.
 * @param {JSON} assetJson in format: { data, included }
 * @returns deep copy of asset data with images denormalized into it.
 */
export const denormalizeAssetData = assetJson => {
  const { data, included } = assetJson || {};
  return denormalizeJsonData(data, included);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking = null, listing = null, provider = null) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {},
    booking,
    listing,
    provider,
  };
  return { ...empty, ...transaction };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = booking => {
  const empty = { id: null, type: 'booking', attributes: {} };
  return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = listing => {
  const empty = {
    id: null,
    type: 'listing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = listing => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = user => {
  const empty = { id: null, type: 'user', attributes: { profile: {} } };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = user => {
  const empty = { id: null, type: 'currentUser', attributes: { profile: {} }, profileImage: {} };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = timeSlot => {
  const empty = { id: null, type: 'timeSlot', attributes: {} };
  return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = availabilityPlan => {
  const empty = { type: 'availability-plan/day', entries: [] };
  return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = availabilityException => {
  const empty = { id: null, type: 'availabilityException', attributes: {} };
  return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = stripeCustomer => {
  const empty = { id: null, type: 'stripeCustomer', attributes: {} };
  return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = stripePaymentMethod => {
  const empty = {
    id: null,
    type: 'stripePaymentMethod',
    attributes: { type: 'stripe-payment-method/card', card: {} },
  };
  const cardPaymentMethod = { ...empty, ...stripePaymentMethod };

  if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
    throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
  }

  return cardPaymentMethod;
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName) => {
  const hasDisplayName = user?.attributes?.profile?.displayName;

  if (hasDisplayName) {
    return user.attributes.profile.displayName;
  } else {
    return defaultUserDisplayName || '';
  }
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
  console.warn(
    `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
  );

  return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;

  if (hasDisplayName) {
    return user.attributes.profile.abbreviatedName;
  } else {
    return defaultUserAbbreviatedName || '';
  }
};

/**
 * Capitalizes the first letter of each word in a given string.
 *
 * This function converts the entire string to lowercase first, then capitalizes
 * the first letter of each word and joins the words back together with spaces.
 *
 * @param {string} text - The input string to be capitalized.
 * @returns {string} - The capitalized string with each word starting with a capital letter.
 *
 * @example
 * const input = "hello world";
 * const result = capitalize(input);
 * console.log(result); // Outputs: "Hello World"
 */
export const capitalize = text => {
  return text
    .toLowerCase() // Convert the whole string to lowercase first
    .split(' ') // Split the string into words
    .map(
      (
        word // Map over each word
      ) => word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the first letter and concatenate with the rest
    )
    .join(' '); // Join the words back into a single string
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (objValue, srcValue, key, object, source, stack) => {
  if (isArray(objValue)) {
    return srcValue;
  }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = code => {
  if (!/^line-item\/.+/.test(code)) {
    throw new Error(`Invalid line item code: ${code}`);
  }
  const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

/**
 * Converts a flat list of categories and subcategories into a nested structure without using push.
 * @param {Array} categoriesList - The list of categories to be converted.
 * @returns {Array} A nested structure where subcategories are grouped under their parent categories.
 */
export function convertCategoriesList(categoriesList = []) {
  return Object.values(
    categoriesList.reduce((acc, { id, label: name, description = '', color = '', parentId, ...rest }) => {
      const category = { id, name, description, color, subcategories: [], ...rest };

      if (parentId) {
        // If it's a subcategory, update the parent's subcategories immutably
        const parentCategory = acc[parentId] || { subcategories: [] };
        acc[parentId] = {
          ...parentCategory,
          subcategories: [...(parentCategory.subcategories || []), category],
        };
      } else {
        // If it's a top-level category
        acc[id] = { ...category, description: '', color: '', _index: Object.keys(acc).length };
      }

      return acc;
    }, {})
  );
}

/**
 * Extracts cart items from the transaction data.
 *
 * @param {Array} transactionData - An array of transaction objects.
 * @returns {Array} An array of cart item objects, each containing:
 * - id: The unique identifier for the item.
 * - title: The title of the item.
 * - image: The URL of the item's image.
 * - count: The quantity of the item in the cart.
 * - type: The type of the item (e.g., product).
 *
 * This function uses the reduce method to accumulate cart items
 * while excluding the "deliveryMethod" entry from the cart.
 */
export const extractCartItems = (transactionData = []) => {
  // base case if there is no transaction data, return an empty array
  if (transactionData.length === 0) {
    return [];
  }
  return transactionData?.reduce((cartItems, transaction) => {
    if (transaction.attributes.protectedData.cart) {
      const cart = transaction.attributes.protectedData.cart;
      Object.keys(cart).forEach(itemId => {
        if (itemId !== 'deliveryMethod') {
          const item = cart[itemId];
          cartItems.push({
            id: itemId,
            title: item.title,
            image: item.image,
            count: item.count,
            type: item.type,
          });
        }
      });

      return cartItems;
    }

    return [];
  }, []);
};

export const generateRandomCode = (uuid = '') => {
  // Convert UUID to an array of characters
  const characters = Array.from(uuid);

  // Reduce to create a hash
  const hash = characters.reduce((acc, char) => {
    const charCode = char.charCodeAt(0); // Get character code
    acc += charCode; // Update accumulator
    return acc % 1000000; // Keep it within 6 digits
  }, 0);

  // Format the number to a 6-digit string
  const formattedCode = String(hash).padStart(6, '0');

  return formattedCode; // Return the final code
};

/**
 * Converts a UUID to a 6-digit number.
 *
 * This function checks if the UUID has already been converted and stored.
 * If it has, it retrieves the corresponding 6-digit number from the map.
 * Otherwise, it computes the 6-digit number by converting the UUID to a
 * big integer and taking the modulo of 1,000,000.
 *
 * @param {string} uuid - The UUID to be converted.
 * @returns {number|null} The corresponding 6-digit number or null if the UUID is not provided.
 */
export const convertUUIDToSixDigitNumber = (uuid) => {
  if (!uuid) {
    return '';
  }
  return uuid.replace(/-/g, '').slice(-6);
}

/**
 * Generates a UUID from a given 6-digit number.
 *
 * This function checks the mapping of 6-digit numbers to UUIDs.
 * If a matching 6-digit number is found, it returns the corresponding UUID.
 *
 * @param {number} sixDigitNumber - The 6-digit number to be converted.
 * @returns {string|null} The corresponding UUID or null if the number is not found.
 */
export const generateUUIDFromSixDigitNumber = sixDigitNumber => {
  if (sixDigitNumber) {
    // Iterate through the mapping to find the UUID corresponding to the 6-digit number
    for (let [uuid, sixDigit] of uuidMap.entries()) {
      if (sixDigit === sixDigitNumber) {
        return uuid;
      }
    }
  }
  return null; // Return null if the 6-digit number is not found or not provided
};

/**
 * Converts a camelCase string to Title Case with spaces.
 *
 * For example, the string "hello-world" will be converted to "Hello World".
 *
 * @param {String} str - The camelCase string to be converted.
 * @returns {String} The converted string in Title Case.
 */
export const convertToTitleCase = (str = '') => {
  if (typeof str !== 'string') return '';

  return str
    .replace(/[_-]/g, ' ') // Replace underscores and hyphens with spaces
    .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
};


/**
 * An array of grouped options for a dropdown menu.
 *
 * Each group contains a label and a list of options.
 *
 * @type {Array<Object>}
 * @property {string} label - The label for the optgroup.
 * @property {Array<Object>} options - An array of options within the optgroup.
 * @property {string} options.value - The value of the option.
 * @property {string} options.label - The display label of the option.
 */

const BrandingIconTypes = {
  DISCOUNT: 'discount',
  BOGO_DEALS: 'bogodeals',
  OFFER: 'offer',
  GIFT: 'gift',
  REFERRAL: 'referral',
  GROUP_DISCOUNT: 'groupdiscount',
  CASHBACK: 'cashback',
  PROMOS: 'promos',
  FREE_DELIVERY: 'freedelivery',
};

export const dealsType = [
  {
    label: 'General Discount',
    icon: BrandingIconTypes.DISCOUNT,
    options: [
      {
        color: '#14D4BD',
        value: 'percentageDiscount',
        label: 'Percentage Discount',
        description: 'Get a certain percentage off the regular price of a product',
      },
      {
        color: '#BF1B82',
        value: 'amountDiscount',
        label: 'Amount Discount',
        description: 'Get a specific amount reduction on the price of a product',
      },
      {
        color: '#00BFFF',
        value: 'bulkDiscount',
        label: 'Bulk Discount',
        description: 'Get discounted prices for purchasing multiple units of a product',
      },
    ],
  },
  {
    label: 'BOGO Deals',
    icon: BrandingIconTypes.BOGO_DEALS,
    options: [
      {
        color: '#FFD700',
        value: 'buyOneGetOneFree',
        label: 'Buy One, Get One Free',
        description: 'Get a free item with the purchase of another item at full price',
      },
      {
        color: '#0E3AD8',
        value: 'buyOneGetOneHalfOff',
        label: 'Buy One, Get One Half Off',
        description: 'Get a discount on the 2nd item when purchasing 2 items at full price',
      },
    ],
  },
  {
    label: 'Bundle Offers',
    icon: BrandingIconTypes.OFFER,
    options: [
      {
        color: '#90EE90',
        value: 'productBundles',
        label: 'Product Bundles',
        description: 'Get multiple products as a package deal at a discounted price',
      },
      {
        color: '#FF69B4',
        value: 'serviceBundles',
        label: 'Service Bundles',
        description: 'Get multiple services into a bundled package at a reduced rate',
      },
    ],
  },
  {
    label: 'Freebies & Gifts',
    icon: BrandingIconTypes.GIFT,
    options: [
      {
        color: '#CD5B45',
        value: 'freeSamples',
        label: 'Free Samples',
        description: 'Get complimentary samples of products for trial and purchase.',
      },
      {
        color: '#ADFF2F',
        value: 'freeGiftsWithPurchase',
        label: 'Free Gifts with Purchase',
        description: 'Get a free gift after purchase of a specific product or at minimum amount',
      },
    ],
  },
  {
    label: 'Flash Sales & Offers',
    icon: BrandingIconTypes.OFFER,
    options: [
      {
        color: '#FF5E51',
        value: 'flashSales',
        label: 'Flash Sales',
        description: 'Get steep discounts on select products for a short duration',
      },
      {
        color: '#800080',
        value: 'limitedTimeOffers',
        label: 'Limited-Time Offers',
        description: 'Get special deals or discounts that are available for a limited period',
      },
    ],
  },
  {
    label: 'Referral Discounts',
    icon: BrandingIconTypes.REFERRAL,
    options: [
      {
        color: '#7B61FF',
        value: 'referralDiscounts',
        label: 'Referral Discounts',
        description: 'Get discounts for referring new customers to the business.',
      },
      {
        color: '#00FFFF',
        value: 'friendDiscounts',
        label: 'Friend Discounts',
        description: 'Get discounts for you and your friend when they make a purchase.',
      },
    ],
  },
  {
    label: 'Group Discounts',
    icon: BrandingIconTypes.GROUP_DISCOUNT,
    options: [
      {
        color: '#00D387',
        value: 'groupDiscounts',
        label: 'Group Discounts',
        description: 'Get discounted prices when you purchase as part of a group',
      },
      {
        color: '#FFA500',
        value: 'viralDiscounts',
        label: 'Viral Discounts',
        description: 'Get discounts by sharing the deal on social networks to reach the threshold',
      },
    ],
  },
  {
    label: 'Cashback Deals',
    icon: BrandingIconTypes.CASHBACK,
    options: [
      {
        color: '#C9C910',
        value: 'cashbackDiscounts',
        label: 'Cashback Discounts',
        description: 'Get an amount back every time you purchase a deal item',
      },
    ],
  },
  {
    label: 'Promos',
    icon: BrandingIconTypes.PROMOS,
    options: [
      {
        color: '#EE82EE',
        value: 'promoCodes',
        label: 'Promo Codes',
        description: 'Get discounts by using the unique merchant discount promo code',
      },
      {
        color: '#11851D',
        value: 'nextVisitDiscount',
        label: 'Next Visit Discount',
        description: 'Get discounts the next time you visit the store within a defined period',
      },
    ],
  },
  {
    label: 'Free Deliveries',
    icon: BrandingIconTypes.FREE_DELIVERY,
    options: [
      {
        color: '#00FF00',
        value: 'freeDeliveries',
        label: 'Free Deliveries',
        description: 'Get free delivery discounts by purchasing this deals',
      },
    ],
  },
];

// Social Handles
export const socialHandles = [
  'instagram',
  'snapchat',
  'pinterest',
  'twitter',
  'facebook',
  'tiktok',
  'website',
];

/**
 * Transforms a flat array of category objects into a hierarchical structure.
 *
 * @param {Array} categories - An array of category objects, each containing:
 *   - id: unique identifier for the category
 *   - label: the name of the category
 *   - parentId: the id of the parent category (if any)
 *
 * @returns {Array} A nested array of categories, where each category can contain child categories.
 */
export const transformCategories = (categories = []) => {
  return categories.reduce((accumulator, currentCategory) => {
    // Destructure the properties from the current category
    const { id: categoryId, label: categoryLabel, parentId: categoryParentId } = currentCategory;

    // Check if the current category has a parent
    if (categoryParentId) {
      // Find the parent category in the accumulator
      const parentCategory = accumulator.find(cat => cat.id === categoryParentId);

      // If the parent category exists, add the current category as a child
      if (parentCategory) {
        parentCategory.childCategory.push({ id: categoryId, label: categoryLabel });
      } else {
        // If the parent category does not exist, create it and add the current category as a child
        accumulator.push({
          id: categoryParentId,
          label: categories.find(cat => cat.id === categoryParentId).label,
          childCategory: [{ id: categoryId, label: categoryLabel }],
        });
      }
    } else {
      // If the current category does not have a parent, ensure it's added to the accumulator
      if (!accumulator.find(cat => cat.id === categoryId)) {
        accumulator.push({ id: categoryId, label: categoryLabel, childCategory: [] });
      }
    }

    // Return the updated accumulator for the next iteration
    return accumulator;
  }, []);
};

export const transformToEvents = transactions => {
  return transactions.map(transaction => {
    const { booking } = transaction;
    const start = new Date(booking.attributes.start);
    const end = new Date(booking.attributes.end);

    return {
      id: transaction.id.uuid,
      title: `${transaction.customer.attributes.profile.displayName} - ${transaction.listing.attributes.title}`,
      start,
      end,
      allDay: false,
      status: booking.attributes.state, // You can use this for further customization
    };
  });
};
