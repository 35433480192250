import { onGetNotifications } from '../../util/api';

// Action Types
export const NOTIFICATION_REQUEST = 'app/NotificationPage/NOTIFICATION_REQUEST';
export const NOTIFICATION_SUCCESS = 'app/NotificationPage/NOTIFICATION_SUCCESS';
export const NOTIFICATION_ERROR = 'app/NotificationPage/NOTIFICATION_ERROR';

// Initial state for the notification reducer
const initialState = {
  loading: false,
  data: [],
  error: null,
};

/**
 * Notification reducer to handle different notification states.
 * @param {Object} state - The current state of the notifications.
 * @param {Object} action - The action dispatched.
 * @returns {Object} - The new state after applying the action.
 */
const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null, // Reset error when making a new request
      };
    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case NOTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default notificationReducer;

/**
 * Action to trigger a notification request.
 * @returns {Object} - The action object.
 */
export const notificationRequest = () => ({
  type: NOTIFICATION_REQUEST,
});

/**
 * Action for a successful notification.
 * @param {Object} data - The data returned from the successful request.
 * @returns {Object} - The action object with the data payload.
 */
export const notificationSuccess = data => ({
  type: NOTIFICATION_SUCCESS,
  payload: data,
});

/**
 * Action for a failed notification.
 * @param {String} error - The error message.
 * @returns {Object} - The action object with the error payload.
 */
export const notificationError = error => ({
  type: NOTIFICATION_ERROR,
  payload: error,
});

/**
 * Thunk action to load notifications data.
 * @returns {Function} - A function that dispatches actions based on the API response.
 */
export const loadData = () => (dispatch, getState) => {
  const { currentUser } = getState()?.user;

  dispatch(notificationRequest());

  return onGetNotifications({ userId: currentUser?.id?.uuid })
    .then(res => {
      dispatch(notificationSuccess(res?.notifications));
    })
    .catch(err => {
      dispatch(notificationError('Error loading data!', err));
    });
};
