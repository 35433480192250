import React from 'react';
import css from '../AddToCartButton/AddToCartButton.module.css';
import { Button } from '..';
import { SERVICE_LISTING_TYPE } from '../../util/types';
import IconCollection from '../IconCollection/IconCollection';

const AddToCartButton = props => {
  const {
    listing,
    count,
    incrementCart,
    isListingPage,
    buttonLabel,
    cartLabel,
    isBooking = false,
    showProductOrderForm = true,
    isOwnListing = false,
    extraParams,
    disabled = false,
  } = props;

  if (isBooking || !showProductOrderForm) {
    return null;
  }

  const increaseCount = () => incrementCart(1, extraParams);
  const decreaseCount = () => incrementCart(-1, extraParams);

  const { bookingEnd, bookingStart, staffId, type } = extraParams || {};
  const submitDisabled = disabled || (type === SERVICE_LISTING_TYPE
    ? !(bookingEnd && bookingStart && staffId && type)
    : !type);


  const currentStock = listing?.currentStock?.attributes?.quantity || 1;
  const isMaxItems = currentStock <= count;

  if (!count || (count === 0 && currentStock > 0)) {
    return (
      <Button
        type="button"
        disabled={submitDisabled}
        onClick={() => {
          if (!isOwnListing) increaseCount();
        }}
        className={css.cartButton}
      >
        {buttonLabel}
      </Button>
    );
  } else {
    return (
      <div className={css.cartContent}>
        {isListingPage && (cartLabel)}
        <div className={css.buttonContainer}>
          <Button className={css.smallButton} onClick={decreaseCount} type="button">
            <IconCollection name="minusIcon" />

          </Button>
          <span className={css.countContainer}>{count}</span>
          <Button className={css.smallButton} disabled={isMaxItems} onClick={increaseCount} type="button">
            <IconCollection name="plusIcon" />

          </Button>
        </div>
      </div>
    );
  }
};

AddToCartButton.defaultProps = {
  isListingPage: false,
};

export default AddToCartButton;