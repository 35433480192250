import { onGetCategories } from '../../util/api';

// Action Types for Interest
export const INTEREST_REQUEST = 'app/InterestPage/INTEREST_REQUEST';
export const INTEREST_SUCCESS = 'app/InterestPage/INTEREST_SUCCESS';
export const INTEREST_ERROR = 'app/InterestPage/INTEREST_ERROR';

// Action Types for Categories
export const CATEGORIES_REQUEST = 'app/CategoriesPage/CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'app/CategoriesPage/CATEGORIES_SUCCESS';
export const CATEGORIES_ERROR = 'app/CategoriesPage/CATEGORIES_ERROR';

// Initial state for the interest and categories reducer
const initialState = {
  loading: false,
  data: [],
  error: null,
  categoriesLoading: false,
  categoriesData: [],
  categoriesError: null,
};

/**
 * Interest reducer to handle different interest and categories states.
 * @param {Object} state - The current state of the interests and categories.
 * @param {Object} action - The action dispatched.
 * @returns {Object} - The new state after applying the action.
 */
const interestReducer = (state = initialState, action) => {
  switch (action.type) {
    // Interest actions
    case INTEREST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null, // Reset error when making a new request
      };
    case INTEREST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case INTEREST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Categories actions
    case CATEGORIES_REQUEST:
      return {
        ...state,
        categoriesLoading: true,
        categoriesError: null, // Reset categories error when making a new request
      };
    case CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesLoading: false,
        categoriesData: action.payload,
      };
    case CATEGORIES_ERROR:
      return {
        ...state,
        categoriesLoading: false,
        categoriesError: action.payload,
      };

    default:
      return state;
  }
};

export default interestReducer;

// Action to trigger an interest request
export const interestRequest = () => ({
  type: INTEREST_REQUEST,
});

// Action for a successful interest request
export const interestSuccess = data => ({
  type: INTEREST_SUCCESS,
  payload: data,
});

// Action for a failed interest request
export const interestError = error => ({
  type: INTEREST_ERROR,
  payload: error,
});

// Action to trigger a categories request
export const categoriesRequest = () => ({
  type: CATEGORIES_REQUEST,
});

// Action for a successful categories request
export const categoriesSuccess = data => ({
  type: CATEGORIES_SUCCESS,
  payload: data,
});

// Action for a failed categories request
export const categoriesError = error => ({
  type: CATEGORIES_ERROR,
  payload: error,
});

/**
 * Thunk action to load both interest and categories data.
 * @returns {Function} - A function that dispatches actions based on the API response.
 */
export const loadData = () => async dispatch => {
  // Load Categories
  dispatch(categoriesRequest());
  try {
    const categoriesResponse = await onGetCategories();
    dispatch(categoriesSuccess(categoriesResponse));
  } catch (error) {
    dispatch(categoriesError(error.message));
  }
};
