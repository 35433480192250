import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './TabNav.module.css';
import { useSelector } from 'react-redux';

const Tab = props => {
  const {
    className,
    id,
    disabled,
    text,
    selected,
    linkProps,
    editListingTabs,
    tabCompletedMaybe,
  } = props;

  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.disabled]: disabled,
  });

  const editListingLinkClasses = classNames(css.editListingLink, {
    [css.editListingSelectedLink]: selected,
    [css.tabCompleted]: tabCompletedMaybe,
    [css.disabled]: disabled,
  });

  return (
    <div id={id} className={className}>
      <NamedLink className={editListingTabs ? editListingLinkClasses : linkClasses} {...linkProps}>
        {editListingTabs ? '' : text}
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = props => {
  const {
    className,
    rootClassName,
    tabRootClassName,
    tabs,
    editListingTabs,
    tabStatusCompleted = {},
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;

  const { currentUser } = useSelector(state => state.user);
  const { userType } = currentUser?.attributes?.profile?.publicData || {};

  const isCustomer = userType === 'customer';

  const updatedTabs = tabs.filter(tab => tab?.id !== "StripePayoutPageTab");
  const tabsToRender = isCustomer ? updatedTabs : tabs;

  return (
    <nav className={classes}>
      {tabsToRender.map((tab, index) => {
        const tabCompletedMaybe =
          Object.keys(tabStatusCompleted).length > 0
            ? tabStatusCompleted[tab?.text.toLowerCase()]
            : false;
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        return (
          <Tab
            tabCompletedMaybe={tabCompletedMaybe}
            key={id}
            id={id}
            className={tabClasses}
            editListingTabs={editListingTabs}
            {...tab}
          />
        );
      })}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
