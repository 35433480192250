/**
 * Export loadData calls from ducks modules of different containers
 */
import {
  loadData as AuthenticationPageLoader,
  loadData,
} from './AuthenticationPage/AuthenticationPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as LandingPageMerchantLoader } from './LandingPage/LandingPageMerchant.duck';
import { loadData as MerchantOnboardingPageLoader } from './MerchantOnboardingPage/MerchantOnboardingPage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as CMSPageLoader } from './CMSPage/CMSPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as ViewListingPageLoader } from './ViewListing/ViewListingPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as PrivacyPolicyPageLoader } from './PrivacyPolicyPage/PrivacyPolicyPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as TermsOfServicePageLoader } from './TermsOfServicePage/TermsOfServicePage.duck';
import { loadData as CartPageLoader } from './CartPage/CartPage.duck';
import { loadData as DealsPageLoader } from './DealsPage/DealsPage.duck';
import { loadData as OrderPageLoader } from './OrderPage/OrderPage.duck';
import { loadData as CalendarPageLoader } from './CalenderPage/CalendarPage.duck';
import { loadData as BookingPageLoader } from './BookingPage/BookingPage.duck';
import { loadData as NotificationPageLoader } from './NotificationPage/NotificationPage.duck';
import { loadData as SendBuzzPageLoader } from './SendBuzzPage/SendBuzzPage.duck';
import { loadData as OrderCompletePageLoader } from './OrderCompletePage/OrderCompletePage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';
import { loadData as InterestPageLoader } from './InterestPage/InterestPage.duck';
import { loadData as PaymentStatusPageLoader } from './PaymentStatusPage/PaymentStatus.duck';
import { loadData as ValidationPageLoader } from './ValidationPage/ValidationPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    AuthenticationPage: {
      loadData: AuthenticationPageLoader,
    },
    PaymentStatusPage: {
      loadData: PaymentStatusPageLoader,
    },
    OrderPage: {
      loadData: OrderPageLoader,
    },
    SendBuzzPage: {
      loadData: SendBuzzPageLoader,
    },
    InterestPage: {
      loadData: InterestPageLoader,
    },
    NotificationPage: {
      loadData: NotificationPageLoader,
    },
    CalendarPage: {
      loadData: CalendarPageLoader,
    },
    BookingPage: {
      loadData: BookingPageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    LandingPageMerchant: {
      loadData: LandingPageMerchantLoader,
    },
    MerchantOnboardingPage: {
      loadData: MerchantOnboardingPageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    CMSPage: {
      loadData: CMSPageLoader,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    DealsPage: {
      loadData: DealsPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    PrivacyPolicyPage: {
      loadData: PrivacyPolicyPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TermsOfServicePage: {
      loadData: TermsOfServicePageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    ViewListingPage: {
      loadData: ViewListingPageLoader,
    },
    CartPage: {
      loadData: CartPageLoader,
    },
    OrderCompletePage: {
      loadData: OrderCompletePageLoader,
    },
    ValidationPage: {
      loadData: ValidationPageLoader,
    },
  };
};

export default getPageDataLoadingAPI;
