import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldRadioButton.module.css';

const IconRadioButton = props => {
  const { checkedClassName, customSvg } = props;
  return (
    <div>
      {customSvg ?
        <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">

          <circle className={props.showAsRequired ? css.requiredCustom : css.notChecked} cx="10" cy="10" r="9.5" fill="white" stroke="#D8D8D8" />
          <circle className={props.showAsRequired ? css.requiredCustom : css.notChecked} cx="10" cy="10" r="10" fill="#FFD046" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1894 7.1535L14.0691 6.0332L8.31371 11.7886L6.01482 9.48975L4.89453 10.61L8.24939 13.9649L8.41539 13.7989L8.47971 13.8632L15.1894 7.1535Z" fill="white" />
        </svg>
        :
        <svg className={props.className} width="14" height="14" xmlns="http://www.w3.org/2000/svg">
          <circle
            className={props.showAsRequired ? css.required : css.notChecked}
            cx="5"
            cy="19"
            r="6"
            transform="translate(2 -12)"
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
          />

          <g
            className={classNames(css.checked, checkedClassName || css.checkedStyle)}
            transform="translate(2 -12)"
            fill="none"
            fillRule="evenodd"
          >
            <circle strokeWidth="2" cx="5" cy="19" r="6" />
            <circle fill="#FFF" fillRule="nonzero" cx="5" cy="19" r="3" />
          </g>
        </svg>}
    </div>
  );
};

IconRadioButton.defaultProps = { className: null };

IconRadioButton.propTypes = { className: string };

const FieldRadioButtonComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    checkedClassName,
    id,
    label,
    showAsRequired,
    customSvg,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const radioButtonProps = {
    id,
    className: customSvg ? css.customInput : css.input,
    component: 'input',
    type: 'radio',
    ...rest,
  };

  return (
    <span className={classes}>
      <Field {...radioButtonProps} />
      <label htmlFor={id} className={classNames(css.label, customSvg && css.customLabel)}>
        <span className={css.radioButtonWrapper}>
          <IconRadioButton
            className={svgClassName}
            checkedClassName={checkedClassName}
            showAsRequired={showAsRequired}
            customSvg={customSvg}
          />
        </span>
        <span className={css.text}>{label}</span>
      </label>
    </span>
  );
};

FieldRadioButtonComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  checkedClassName: null,
  label: null,
};

FieldRadioButtonComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  checkedClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several RadioButtones to an array of selected values
  name: string.isRequired,

  // RadioButton needs a value that is passed forward when user checks the RadioButton
  value: string.isRequired,
};

export default FieldRadioButtonComponent;
