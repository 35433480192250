import { fetchCurrentUser } from '../../ducks/user.duck';
import { onGetDealsCategoriesList, searchAlgoliaData } from '../../util/api';

/** Action Types */
const DEAL_CATEGORIES_REQUEST = 'app/DealsPage/DEAL_CATEGORIES_REQUEST';
const DEAL_CATEGORIES_SUCCESS = 'app/DealsPage/DEAL_CATEGORIES_SUCCESS';
const DEAL_CATEGORIES_ERROR = 'app/DealsPage/DEAL_CATEGORIES_ERROR';

/** Initial State */
const initialState = {
  loading: false,
  data: {
    dealCategories: [],
  },
  error: null,
};

/** Action Creators */
export const fetchDealCategoriesRequest = () => ({
  type: DEAL_CATEGORIES_REQUEST,
});

export const fetchDealCategoriesSuccess = data => ({
  type: DEAL_CATEGORIES_SUCCESS,
  payload: data,
});

export const fetchDealCategoriesError = error => ({
  type: DEAL_CATEGORIES_ERROR,
  payload: error,
});

/** Reducer */
const dealCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEAL_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DEAL_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          dealCategories: action.payload,
        },
      };
    case DEAL_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default dealCategoriesReducer;

/** Thunk Action Creator */
export const loadData = () => async (dispatch, getState, sdk) => {
  dispatch(fetchDealCategoriesRequest());

  await dispatch(fetchCurrentUser());
  const currentUser = getState().user?.currentUser || {};
  const currentUserId = currentUser?.id?.uuid || '';

  // Using then and catch for promise handling
  return onGetDealsCategoriesList()
    .then(response => {
      searchAlgoliaData({
        indexName: process.env.REACT_APP_ALGOLIA_DEALS_INDEX,
        querySearch: "",
        filters: {
          facets: ["publicData.categoryLevel2"],
          filters: `author.id:${currentUserId}`,
          page: 0,
          hitsPerPage: 0,
        }
      })
        .then(res => {
          const facets = res?.data?.facets || {};
          const subCategories = facets["publicData.categoryLevel2"] || {};
          const updatedResponse = Array.isArray(response) ? response : response ? [response] : [];

          const updatedDealCategories = updatedResponse.map(dealCategory => {
            const count = subCategories[dealCategory?.id];
            const countMaybe = count ? { count } : {};
            return {
              ...dealCategory,
              ...countMaybe,
            };
          });

          dispatch(fetchDealCategoriesSuccess(updatedDealCategories));
        })
    })
    .catch(error => {
      dispatch(fetchDealCategoriesError(error.message || 'Failed to load data'));
    });
};
